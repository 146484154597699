import "./App.css";
import { useState } from "react";
import CBPay from "./CBPay_Components/CBPay";
import CBPayGenerateUrl from "./CBPay_Components/CBPayGenerateUrl";
import CBPayContext from "./CBPay_Components/CBPayContext";
import { CBPayGenerateUrlSource, CBPayIFrameSource } from "./sourceCode";
import Modal from "./Modal";

function App() {
  const appId = process.env.REACT_APP_APP_ID;
  const [activeComponent, setActiveComponent] = useState("generate");
  const [showModal, setShowModal] = useState(false);
  const [showGenerateSource, setShowGenerateSource] = useState(false);

  const handleClick = (component) => {
    setActiveComponent(component);
  };

  const handleShowSource = (component) => {
    if (component === "generate") {
      setShowGenerateSource(true);
    }
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setShowGenerateSource(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="button-container">
          <button onClick={() => handleClick("generate")} className="switch-button">
            Generate URL
          </button>
          <button onClick={() => handleClick("initOnRamp")} className="switch-button">
            initOnRamp
          </button>
        </div>
        <Modal show={showModal} content={showGenerateSource ? CBPayGenerateUrlSource : CBPayIFrameSource} onClose={handleClose} />
        <CBPayContext.Provider value={{ appId }}>
          {activeComponent === "generate" ? (
            <CBPayGenerateUrl />
          ) : (
            <CBPay />
          )}
        </CBPayContext.Provider>
        <div className="source-button-container">
          <button onClick={() => handleShowSource("generate")} className="source-button">
            Show Source (Generate URL)
          </button>
          <button onClick={() => handleShowSource("initOnRamp")} className="source-button">
            Show Source (initOnRamp)
          </button>
        </div>
      </header>
    </div>
  );
}

export default App;
