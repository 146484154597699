

const CBPayGenerateUrlSource = `
import React, { useState, useEffect, useContext } from "react";
import { generateOnRampURL } from "@coinbase/cbpay-js";
import CBPayContext from "./CBPayContext";
import Wallet from "../wallet/wallet";
import styles from "./CBPay.css";
import BuyWithCoinbaseButton from "./BuyWithCoinbaseButton";

function CBPayGenerateUrl() {
  const [walletStateVariables, setwalletStateVariables] = useState({
    walletAddress: "",
    balance: "",
    network: "",
  });

  const [onRampURL, setOnRampURL] = useState();
  const [isReady, setIsReady] = useState(false);
  const [src, setSrc] = useState("");

  const { appId } = useContext(CBPayContext);

  useEffect(() => {
    if (!walletStateVariables.walletAddress) return;

    setOnRampURL(
      generateOnRampURL({
        appId: appId,
        destinationWallets: [
          {
            address: walletStateVariables.walletAddress,
            blockchains: ["ethereum"],
          },
        ],
      })
    );

    setIsReady(true);
  }, [walletStateVariables.walletAddress, appId]);

  const onWalletClick = (walletVariables) => {
    setwalletStateVariables(walletVariables);
    console.log("Wallet Address", walletVariables.walletAddress);
  };
  const handleClick = () => {
    setSrc(onRampURL);
  };

  return (
    <div className={styles.cbPayIFrameContainer}>
      <div className={styles.leftContainer}>
        <Wallet onWalletClick={onWalletClick} />
        <h3>Wallet Address: {walletStateVariables.walletAddress}</h3>
        <h4>Balance= {walletStateVariables.balance} ETH</h4>
        <h4>Network Name: {walletStateVariables.network}</h4>
        <BuyWithCoinbaseButton isReady={isReady} onClick={handleClick} />
        {src && ( // Only show the iframe when the src is not empty
          <iframe
            src={src}
            name="cbpay-frame"
            title="CB Pay"
            width="450px"
            height="600px"
            style={{ border: "none" }}
          />
        )}
      </div>
    </div>
  );
}
export default CBPayGenerateUrl;
`;

const CBPayIFrameSource = `
import React, { useState, useEffect, useContext } from "react";
import styles from "./CBPay.css";
import Wallet from "../wallet/wallet";
import BuyWithCoinbaseButton from "./BuyWithCoinbaseButton";
import { initOnRamp } from "@coinbase/cbpay-js";
import CBPayContext from "./CBPayContext";

function CBPayIFrame() {
  const [isReady, setIsReady] = useState(false);
  const [onrampInstance, setOnrampInstance] = useState();
  const [walletStateVariables, setwalletStateVariables] = useState({
    walletAddress: '',
    balance: '',
    network: '',
  });
  const { appId } = useContext(CBPayContext);
  const onWalletClick = (walletVariables) => {
    setwalletStateVariables(walletVariables);
    console.log('Wallet Address', walletVariables.walletAddress);
  };

  useEffect(() => {
    if (!walletStateVariables.walletAddress) return;

    const initCBPay = () => {
      initOnRamp({
        appId,
        widgetParameters: {
          destinationWallets: [
            {
              address: walletStateVariables.walletAddress,
              blockchains: ['ethereum'],
            },
          ],
        },
        onSuccess: () => {
          console.log('success');
        },
        onExit: () => {
          console.log('exit');
        },
        onEvent: (event) => {
          console.log('event', event);
        },
        experienceLoggedIn: 'embedded',
        experienceLoggedOut: 'popup',
        closeOnExit: true,
        closeOnSuccess: true,
        embeddedContentStyles: {
          width: "450px",
          height: "600px",
          target: "#embedded-coinbase-content",
          top: "50%",
          position:"inherit",
        },
      }, (_, instance) => {
        setOnrampInstance(instance);
        setIsReady(true);
      });
    };

    initCBPay();

    return () => {
      onrampInstance?.destroy();
    };
  }, [appId, walletStateVariables.walletAddress]);

  const handleClick = () => {
    console.log('clicked Buy with Coinbase')
    onrampInstance?.open();
  };

  return (
    <div className={styles.cbPayIFrameContainer}>
      <div className={styles.leftContainer}>
        <Wallet onWalletClick={onWalletClick} />
        <h3>Wallet Address: {walletStateVariables.walletAddress}</h3>
        <h4>Balance= {walletStateVariables.balance} ETH</h4>
        <h4>Network Name: {walletStateVariables.network}</h4>
        <BuyWithCoinbaseButton isReady={isReady} onClick={handleClick} />
      </div>
      <div className={styles.embeddedContent} id="embedded-coinbase-content"></div>
    </div>
  );
}

export default CBPayIFrame;
`;

export { CBPayGenerateUrlSource, CBPayIFrameSource };