import React from 'react';
import cbbutton from '../Images/button-cbPay-normal-generic.png';
import styles from './BuyWithCoinbaseButton.module.css';

export default function BuyWithCoinbaseButton({ isReady, onClick }) {
  return (
    <div>
      <button
        className={styles.buttonContainer}
        id="cbpay-button-container"
        onClick={onClick}
        disabled={!isReady}
      >
        <img
          src={cbbutton}
          alt="coinbase pay button"
          style={{ opacity: isReady ? 1.0 : 0.4 }}
        />
      </button>
    </div>
  );
}
