import React from "react";
import { ethers } from "ethers";
import "./wallet.css";

function Wallet({ onWalletClick }) {
  async function connectWallet() {
    console.log("Connecting to wallet...");
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        let signer = provider.getSigner();
        const walletAddress = await signer.getAddress();
        const network_name = await provider.getNetwork();
        const network = network_name.name;
        const balance = ethers.utils.formatEther(await signer.getBalance());
        const walletVariables = {
          walletAddress,
          balance,
          network,
        };

        console.log("Connected to wallet", walletAddress);

        onWalletClick(walletVariables);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Coinbase wallet not detected");
    }
  }
  return (
    <div>
      <button className="button-arounder" onClick={connectWallet}>Connect to wallet</button>
    </div>
  );
}
export default Wallet;
