import React, { Component } from 'react';
import './Modal.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FaCopy, FaTimes } from 'react-icons/fa';

const CodeBlock = ({ content }) => (
  <SyntaxHighlighter
    language="javascript"
    style={tomorrow}
    customStyle={{ fontSize: '12px' }}
    showLineNumbers
    wrapLines
  >
    {content}
  </SyntaxHighlighter>
);

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCopied: false,
    };
  }

  handleCopyClick = async () => {
    const { content } = this.props;
    try {
      await navigator.clipboard.writeText(content);
      this.setState({ isCopied: true });
      setTimeout(() => {
        this.setState({ isCopied: false });
      }, 2000);
    } catch (error) {
      console.error('Failed to copy code to clipboard:', error);
    }
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const { show, onClose, content } = this.props;
    const { isCopied } = this.state;

    if (!show) {
      return null;
    }

    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={this.stopPropagation}>
          <div className="code-header">
            <button className="copy-button" onClick={this.handleCopyClick}>
              <FaCopy />
            </button>
            <button className="modal-close" onClick={onClose}>
              <FaTimes />
            </button>
          </div>
          {isCopied && <div className="copy-message">Copied!</div>}
          <CodeBlock content={content} />
        </div>
      </div>
    );
  }
}

export default Modal;
